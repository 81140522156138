<form [formGroup]="roleForm" [style.margin-bottom.px]="20">
  <div>
    <mat-form-field>
      <mat-label>Namn</mat-label>
      <input
        formControlName="roleName"
        matInput
        placeholder="Namn"
        type="text"
      >
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>Beskrivning</mat-label>
      <textarea
        style="overflow: hidden"
        cdkTextareaAutosize
        formControlName="roleDescription"
        matInput></textarea>
    </mat-form-field>
  </div>

  <button
    (click)="updateRole()"
    [disabled]="!roleForm.valid"
    color="primary"
    mat-raised-button type="button">Spara
  </button>
</form>