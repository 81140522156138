import {HttpClient} from '@angular/common/http'
import {Injectable, signal, WritableSignal} from '@angular/core'
import {map, Observable, switchMap} from 'rxjs'
import {Role, SpbUser} from 'sparbanken-syd-auth-backend'
import {environment} from '../../environments/environment'
import {ServiceBase, Wait} from './service-base.class'

@Injectable({
  providedIn: 'root'
})

// TODO: USE OWN TYPES INSTEAD OF ROLE[]
export class RoleService extends ServiceBase {
  public roleExpandedElement$: WritableSignal<SpbUser | null> = signal(null)
  public roles$: WritableSignal<Role[]> = signal([])
  public roleMap$: WritableSignal<any> = signal(undefined)

  constructor(private readonly httpClient: HttpClient) {
    super()
  }

  /**
   * Save a role to database
   */
  @Wait('Sparar', 'Sparar roll...')
  public updateRole(role: Role): Observable<Role[]> {
    const url = `${environment.userAdminApi}/roles`
    return this.httpClient.put<Role>(url, role)
      .pipe(
        switchMap(() => this.getAllRoles())
      )
  }

  /**
   * get all roles from database
   */
  public getAllRoles(): Observable<Role[]> {
    const url = `${environment.userAdminApi}/roles`

    return this.httpClient.get<Role[]>(url).pipe(
      map((roles: Role[]) => {
        const filteredRoles = roles.sort((a, b) => {
          if (a.siteKey === 'general') {
            return -1
          }
          return a.siteName!.localeCompare(b.siteName!)
        })

        this.roles$.set(filteredRoles)

        const roleMap = filteredRoles.reduce((acc, role) => {
          // Check if the siteKey already exists in the accumulator
          if (!acc[role.siteKey]) {
            // If it doesn't exist, create a new entry with an empty roles array
            acc[role.siteKey] = {
              siteName: role.siteName!,
              roles: []
            }
          }
          // Add the current role to the roles array for this siteKey
          acc[role.siteKey].roles.push(role)
          return acc
        }, {} as Record<string, { siteName: string; roles: Role[] }>)

        // Set the roleMap observable with the constructed role map values
        this.roleMap$.set(Object.values(roleMap))
        return roles
      })
    )
  }

}
