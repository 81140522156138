<h3>Loggar</h3>

<div class="logs-holder">
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>Hämta loggar från och med</mat-label>
    <input (dateInput)="inputDate($event)" [matDatepicker]="pickerValid"
           [value]="dateInTemplate" matInput>
    <mat-datepicker-toggle [for]="pickerValid"
                           matSuffix></mat-datepicker-toggle>
    <mat-datepicker #pickerValid></mat-datepicker>
  </mat-form-field>


  <button (click)="updateLogs()" class="update-button" mat-raised-button>
    Uppdatera loggar
  </button>
</div>


<spb-logs-list [timeStamp]="timeStamp" [logs]="spbLogs"></spb-logs-list>
