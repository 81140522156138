<div class="header">
  <!-- Router link here is default ["/"] -->
  <spb-logo title="Behörighetshantering"></spb-logo>
  <div [style.flex-grow]="1"></div>
  <div *ngIf="(configService.logInState$ | async) as user">
    <span>{{ user.name }}</span>
    <button [matMenuTriggerFor]="menu" mat-button>
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</div>

<!-- Add other fancy navigations here -->
<mat-menu #menu="matMenu">
  @if (configService.isSuperAdmin$()) {
    <button mat-menu-item routerLink="/admin" mat-menu-item>Super Admin</button>
  }
  <button mat-menu-item routerLink="/home">Användare</button>
  <button mat-menu-item routerLink="/roles">Behörigheter</button>
  @if (false) {
    <button mat-menu-item routerLink="/logs">Loggar</button>
  }
  <button (click)="configService.logout()" mat-menu-item>Logga ut</button>
</mat-menu>
