import {DatePipe} from '@angular/common'
import {Component, Input, OnChanges, ViewChild} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatPaginator} from '@angular/material/paginator'
import {MatSort, MatSortHeader} from '@angular/material/sort'
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table'
import {UserService} from '../../services/user.service'
import {SpbLog} from '../../types'
import {LogsDetailsComponent} from '../logs-details/logs-details.component'

@Component({
    selector: 'spb-logs-list',
    templateUrl: './logs-list.component.html',
    styleUrls: ['./logs-list.component.scss'],
    standalone: true,
    imports: [MatFormField, MatLabel, MatInput, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow, MatPaginator, DatePipe]
})
export class LogsListComponent implements OnChanges {
  /**
   * The boilerplate paginator.
   */
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null

  /**
   * The boilerplate sorter.
   */
  @ViewChild(MatSort, {static: true}) sort: MatSort = new MatSort()

  /**
   * The list needs logs and users (from home component)
   */
  @Input() logs: SpbLog[] = []
  @Input() timeStamp: number | undefined = undefined

  /**
   * The data in the table
   */
  public dataSource: MatTableDataSource<SpbLog> = new MatTableDataSource<SpbLog>()

  /**
   * The columns in the table
   */
  public displayedColumns: string[] = ['timeStamp', 'action']

  constructor(
    private readonly dialog: MatDialog,
    private readonly userService: UserService
  ) {
  }

  /**
   * Select a row to show more info
   */
  public selectRow(row: SpbLog) {
    const data: SpbLog = row
    this.dialog.open(LogsDetailsComponent, {
      data
    })
  }

  /**
   * To apply the filter when search begins
   */
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  /**
   * Paginator and sort always needs to be after this.dataSource is set, to work
   */

  ngOnChanges() {
    this.dataSource.data = this.logs
      .map(log => ({
        ...log,
        action:
          `${this.getLoggedUserName(log.loggedItem.userSub)} ${log.action}`
      }))

    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }


  /**
   * Change sub to userName
   */
  public getLoggedUserName(userSub: string): string {
    const user = this.userService.users$().find(user => user.sub === userSub)
    return user?.name ?? 'Okänd användare'
  }

}
