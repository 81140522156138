import {JsonPipe} from '@angular/common'
import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  ViewChild
} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {MatSort, MatSortModule} from '@angular/material/sort'
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table'
import {filter, switchMap} from 'rxjs/operators'
import {Role} from 'sparbanken-syd-auth-backend'
import {AdminService} from '../../services/admin.service'
import {AreYouSureComponent} from '../are-you-sure/are-you-sure.component'
import {EditRoleComponent} from '../edit-role/edit-role.component'

@Component({
  selector: 'spb-role-list',
  standalone: true,
  imports: [
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRowDef,
    MatRowDef,
    MatTable,
    MatHeaderCell,
    MatCell,
    MatColumnDef,
    MatSort,
    JsonPipe,
    MatHeaderRow,
    MatRow,
    MatButton,
    MatSortModule
  ],
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, {static: true}) sort!: MatSort

  public displayedColumns: string[] = ['roleName', 'roleDescription', 'siteName', 'delete']
  public dataSource = new MatTableDataSource<Role>([])
  private readonly adminService = inject(AdminService)
  private readonly dialog = inject(MatDialog)

  public ngOnInit(): void {
    this.loadRoles()
  }

  public editRole(role: Role): void {
    this.openRoleDialog(role)
  }

  public createRole(): void {
    this.openRoleDialog({})
  }

  public deleteRole(role: Role): void {
    this.dialog
      .open(AreYouSureComponent, {data: role})
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() => this.adminService.deleteRole(role))
      )
      .subscribe({
        next: () => this.loadRoles()
      })
  }

  public ngAfterViewInit() {
    this.dataSource.sort = this.sort
  }


  private openRoleDialog(data: Partial<Role>): void {
    this.dialog.open(EditRoleComponent, {data, minWidth: 699})
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap((updatedRole: Role) => this.adminService.putRole(updatedRole)),
        switchMap(() => this.adminService.getRoles())
      )
      .subscribe({
        next: roles => this.dataSource.data = roles
      })
  }

  private loadRoles(): void {
    this.adminService.getRoles().subscribe({
      next: roles => {
        this.dataSource.data = roles
      }
    })
  }
}
