import {Component, Inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA, MatDialogActions,
  MatDialogClose, MatDialogContent,
  MatDialogRef, MatDialogTitle
} from '@angular/material/dialog'
import {Role} from 'sparbanken-syd-auth-backend'

@Component({
  selector: 'spb-are-you-sure',
  standalone: true,
  imports: [
    MatDialogClose,
    MatButton,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions
  ],
  templateUrl: './are-you-sure.component.html',
  styleUrl: './are-you-sure.component.scss'
})
export class AreYouSureComponent {
  constructor(
    public dialogRef: MatDialogRef<AreYouSureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Role) {
  }

  public delete() {
    this.dialogRef.close(this.data)
  }
}
