/**
 * Store some variables that we want to access
 * here and there
 */

/**
 * The routes are good to be consistent with.
 */
export const LOGIN_PATH = 'login'
export const HOME_PATH = 'home'
export const ROLES = 'roles'
export const LOGS = 'logs'
export const SUPER_ADMIN = 'admin'
