import {Component, inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {concatMap, delay, from, of} from 'rxjs'
import {AdminService} from '../services/admin.service'
import {RoleListComponent} from './role-list/role-list.component'

interface Role {
  siteName: string
  roleDescription: string
  roleKey: string
  siteKey: string
  roleName: string
}


@Component({
  selector: 'spb-admin',
  standalone: true,
  imports: [
    RoleListComponent,
    MatButton
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})


export class AdminComponent {

  private readonly adminService = inject(AdminService)

  public saveRole() {

    const roles: Role[] = [
      {
        siteName: 'Omsättningsverktyget',
        roleDescription: 'Denna behörighet kan logga in och titta samt skriva ut men inte sätta värden (även om det ser ut så på knapparna)',
        roleKey: 'renewalToolViewer',
        siteKey: 'ov.internal',
        roleName: 'OV - Läs'
      },
      {
        siteName: 'Enkla lånelöftet',
        roleDescription: 'Denna behörighet ger fullständiga rättigheter för att använda enkla lånelöftet',
        roleKey: 'admin',
        siteKey: 'll.internal',
        roleName: 'Enkla lånelöftet - Administratör'
      },
      {
        siteName: 'Enkla lånelöftet',
        roleDescription: 'Användare med denna behörighet kan endast titta (read-only) på lånelöfte genom att söka på sökandes personnummer.',
        roleKey: 'loanPromiseReadOnly',
        siteKey: 'll.internal',
        roleName: 'Enkla lånelöftet - Titta'
      },
      {
        siteName: 'Aktbevakning',
        roleDescription: 'Denna behörighet har rättigheter till brister och utvalda fel',
        roleKey: 'internalSupport',
        siteKey: 'ab.internal',
        roleName: 'Aktbevakning - Internstöd'
      },
      {
        siteName: 'Spärra BankID',
        roleDescription: 'Denna behörighet kan använda verktyget till att spärra BankID',
        roleKey: 'blockBankIdUser',
        siteKey: 'bb.internal',
        roleName: 'Spärra BankID - Användare'
      },
      {
        siteName: 'Investeringsrådgivning',
        roleDescription: 'Denna behörighet kan göra investeringsrådgivning',
        roleKey: 'irUser',
        siteKey: 'ir.internal',
        roleName: 'IR - Användare'
      },
      {
        siteName: 'Certifikat',
        roleDescription: 'Denna behörighet kan administrera certifikatstjänsten (lägga in nya certifikat osv)',
        roleKey: 'certAdmin',
        siteKey: 'cert-app.internal',
        roleName: 'Certifikattjänsten - Admin'
      },
      {
        siteName: 'Legitimera',
        roleDescription: 'Denna behörighet ger rättighet att använda legitimera',
        roleKey: 'idUser',
        siteKey: 'legitimera.internal',
        roleName: 'Legitimera - Användare'
      },
      {
        siteName: 'Sparkonto (Compricer)',
        roleDescription: 'Denna behörighet ger administratörsrättigheter för att använda sparkonto (vilken främst används genom compricer).',
        roleKey: 'savingsAccountAdmin',
        siteKey: 'sk.internal',
        roleName: 'Compricer - Admin'
      },
      {
        siteName: 'Vägval Borgo – Syd',
        roleDescription: 'Denna behörighet ger rättighet att använda Vägval Borgo-Syd (Kalpylatorn)',
        roleKey: 'bkAppUser',
        siteKey: 'bk.internal',
        roleName: 'Vägval Borgo-Syd - Användare'
      },
      {
        siteName: 'Generella behörigheter',
        roleDescription: 'En behörighet för anställda, ger rättighet att 1: Logga in i tjänsteportalen. 2: Se enkla kundkännedomen',
        roleKey: 'employee',
        siteKey: 'general',
        roleName: 'Anställd'
      },
      {
        siteName: 'Borgo ärendevy',
        roleDescription: 'Denna behörighet ger fullständiga rättigheter för att använda borgo ärendevy',
        roleKey: 'boLoanAdmin',
        siteKey: 'bol.internal',
        roleName: 'Borgo ärendevy - Användare'
      },
      {
        siteName: 'Investeringsrådgivning',
        roleDescription: 'Denna behörighet ger administratörsrättigheter för att använda investeringsrådgivningen. Kan exempelvis ta bort kunder.',
        roleKey: 'irAdmin',
        siteKey: 'ir.internal',
        roleName: 'IR - Admin'
      },
      {
        siteName: 'Omsättningsverktyget',
        roleDescription: 'Denna behörighet kan administrera  / sätta värden i omsättningsverktyget',
        roleKey: 'renewalToolAdmin',
        siteKey: 'ov.internal',
        roleName: 'OV - Ändra'
      },
      {
        siteName: 'Spärra BankID',
        roleDescription: 'Denna behörighet kan följa upp och se statistik för spärra BankID',
        roleKey: 'blockBankIdAdmin',
        siteKey: 'bb.internal',
        roleName: 'Spärra BankID - Admin'
      },
      {
        siteName: 'Enkla privatlånet',
        roleDescription: 'Denna behörighet ger fullständiga rättigheter för att använda enkla blancolånet, även kallad enkla privatlånet',
        roleKey: 'blancoAdmin',
        siteKey: 'bl.internal',
        roleName: 'Enkla Blancolånet - Användare'
      },
      {
        siteName: 'Legitimera',
        roleDescription: 'Denna behörighet ger administratörsrättigheter för legitimera',
        roleKey: 'idAdmin',
        siteKey: 'legitimera.internal',
        roleName: 'Legitimera - Admin'
      },
      {
        siteName: 'Investeringsrådgivning',
        roleDescription: 'Denna behörighet ger portföljrättigheter för att använda investeringsrådgivningen. Kan skapa och uppdatera portföljer.',
        roleKey: 'irPfAdmin',
        siteKey: 'ir.internal',
        roleName: 'IR - Portfölj Admin'
      },
      {
        siteName: 'Aktbevakning',
        roleDescription: 'Denna behörighet har rättigheter till två fel',
        roleKey: 'customerSupport',
        siteKey: 'ab.internal',
        roleName: 'Aktbevakning - Kundstöd'
      },
      {
        siteName: 'Bolånerådgivning',
        roleDescription: 'Denna behörighet ger fullständiga rättigheter för att använda bolånerådgivningen',
        roleKey: 'brUser',
        siteKey: 'br.internal',
        roleName: 'BR - Användare'
      },
      {
        siteName: 'Aktbevakning',
        roleDescription: 'Denna behörighet ger rättighet att titta på akt',
        roleKey: 'office',
        siteKey: 'ab.internal',
        roleName: 'Aktbevakning - Titta'
      },
      {
        siteName: 'Aktbevakning',
        roleDescription: 'Denna behörighet ger fullständiga rättigheter för att använda aktbevakning (hantera akter)',
        roleKey: 'credit',
        siteKey: 'ab.internal',
        roleName: 'Aktbevakning - Kredit'
      },
      {
        siteName: 'Omsättningsverktyget',
        roleDescription: 'Denna behörighet behövs för att kunna se önskad ombindning',
        roleKey: 'renewalToolBinding',
        siteKey: 'ov.internal',
        roleName: 'OV - Bindningar'
      },
      {
        siteName: 'Fondväljaren',
        roleDescription: 'Denna behörighet ger fullständiga rättigheter för att använda fondväljaren via tjänsteportalen',
        roleKey: 'simpleSavingsGuideAdmin',
        siteKey: 'fondvaljaren.internal',
        roleName: 'Fondväljaren - Admin'
      },
      {
        siteName: 'Certifikat',
        roleDescription: 'Denna behörighet kan använda certifikatstjänsten (titta på certifikat)',
        roleKey: 'certUser',
        siteKey: 'cert-app.internal',
        roleName: 'Certifikattjänsten - Användare'
      }
    ]

    const delayDuration = 1000

    from(roles).pipe(
      concatMap(role =>
        of(role).pipe(
          delay(delayDuration),
          concatMap(delayedRole => this.adminService.putRole(delayedRole))
        )
      )
    ).subscribe({
      next: () => console.log('Roll sparad'),
      complete: () => console.log('Alla roller har sparats')
    })


  }


}
