import {Component, Inject} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogActions, MatDialogClose } from '@angular/material/dialog'
import {SpbUser} from 'sparbanken-syd-auth-backend'
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'spb-confirm-delete-dialog',
    templateUrl: './confirm-delete-dialog.component.html',
    styleUrl: './confirm-delete-dialog.component.scss',
    standalone: true,
    imports: [MatDialogTitle, MatDialogActions, MatButton, MatDialogClose]
})
export class ConfirmDeleteDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public user: SpbUser) {
  }

}
