import {registerLocaleData} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeFr from '@angular/common/locales/fr'
import {APP_INITIALIZER, ApplicationConfig} from '@angular/core'
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS
} from '@angular/material-luxon-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {provideAnimations} from '@angular/platform-browser/animations'
import {provideRouter, withHashLocation} from '@angular/router'
import routes from './app-routes'
import {authInterceptor} from './application/auth.interceptor'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'
import {responseInterceptor} from './application/response.interceptor'
import {ConfigService} from './services/config.service'

registerLocaleData(localeFr, 'fr', localeFrExtra)

export const appConfig: ApplicationConfig = {
  providers: [
    LOCAL_STORAGE_PROVIDERS,
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService],
      multi: true
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true, firstDayOfWeek: 1}
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline',
        subscriptSizing: 'dynamic'
      }
    },
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true, firstDayOfWeek: 1}
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor])),
    provideAnimations(),
    provideRouter(routes,
      withHashLocation())
  ]
}