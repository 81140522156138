<mat-form-field>
  <mat-label>Skriv för att söka</mat-label>
  <input (keyup)="applyFilter($event)" autocomplete="off" matInput>
</mat-form-field>


<div class="holder">
  <table [dataSource]="dataSource" class="mat-elevation-z8" mat-table matSort>

    <!-- timeStamp Column -->
    <ng-container matColumnDef="timeStamp">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="timeStamp" scope="col"  style="width:25%">Datum</th>
      <td (click)="selectRow(row)" *matCellDef="let row"
          mat-cell>{{row.timeStamp | date: 'yyyy-MM-dd, HH:mm'}}</td>
    </ng-container>


    <!-- action Column -->
    <ng-container matColumnDef="action">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="action" scope="col">Action</th>
      <td (click)="selectRow(row)" *matCellDef="let row" mat-cell>{{row.action}}</td>
    </ng-container>


    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

    <!-- Row shown when there is no matching data. -->
    <tr *matNoDataRow class="mat-row ">
      <td [colSpan]="displayedColumns.length" class="mat-cell no-data">Ingen data...</td>
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 50]" [pageSize]="20" aria-label="Select page"></mat-paginator>

</div>
