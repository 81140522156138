<h2>Administrera roller</h2>

<div class="create-role">
  <button mat-raised-button (click)="createRole()" color="primary">Skapa ny roll</button>
</div>

<table [dataSource]="dataSource" class="mat-elevation-z8" mat-table matSort>
  <!-- Role Name Column -->
  <ng-container matColumnDef="roleName">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Rollens namn</th>
    <td class="pointer" (click)="editRole(role)" mat-cell
        *matCellDef="let role"> {{ role.roleName }}
    </td>
  </ng-container>

  <!-- Role Description Column -->
  <ng-container matColumnDef="roleDescription">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Rollens beskrivning
    </th>
    <td class="pointer" (click)="editRole(role)" mat-cell
        *matCellDef="let role"> {{ role.roleDescription }}
    </td>
  </ng-container>

  <!-- Site Name Column -->
  <ng-container matColumnDef="siteName">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Tjänstens namn</th>
    <td class="pointer" (click)="editRole(role)" mat-cell
        *matCellDef="let role"> {{ role.siteName }}
    </td>
  </ng-container>


  <!-- Delete Column -->
  <ng-container matColumnDef="delete">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Radera</th>
    <td mat-cell *matCellDef="let role" (click)="deleteRole(role)">
      <div class="delete pointer">
        <span class="material-symbols-outlined">delete</span>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row
      *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

