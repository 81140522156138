<h2
  mat-dialog-title>{{ data.roleName ? 'Redigera: ' + data.roleName : 'Ny Roll' }}</h2>

<mat-dialog-content [formGroup]="roleForm">
  <div class="dialog-content-container">
    <!-- Role Name Field -->
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Namn</mat-label>
      <input formControlName="roleName" matInput placeholder="Ange rollens namn"
             type="text"/>
    </mat-form-field>

    <!-- Role Description Field -->
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Beskrivning</mat-label>
      <textarea formControlName="roleDescription" matInput
                placeholder="Ange rollbeskrivning"></textarea>
    </mat-form-field>

    <!-- Role Key Field -->
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Rollnyckel</mat-label>
      <input formControlName="roleKey" matInput placeholder="Rollnyckel"
             type="text"/>
    </mat-form-field>

    <!-- Site Name Field -->
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Tjänstens namn</mat-label>
      <input formControlName="siteName" matInput placeholder="Tjänstens namn"
             type="text"/>
    </mat-form-field>

    <!-- Site Key Field -->
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Sidnyckel</mat-label>
      <input formControlName="siteKey" matInput placeholder="Sidnyckel"
             type="text"/>
    </mat-form-field>

    <!-- ID Field (Read-only) -->
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>ID</mat-label>
      <input formControlName="id" matInput placeholder="ID skapas automatiskt"
             type="text" readonly/>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="dialog-actions">
  <button mat-raised-button mat-dialog-close>Avbryt</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="roleForm.value"
          [disabled]="!roleForm.valid">
    Spara
  </button>
</mat-dialog-actions>
