import {Component} from '@angular/core'
import {ConfigService} from '../../services/config.service'
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';
import { ThemeModule } from '@sparbanken-syd/sparbanken-syd-theme';

@Component({
    selector: 'spb-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [ThemeModule, NgIf, MatButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, RouterLink, AsyncPipe]
})
export class HeaderComponent {
  constructor(
    public configService: ConfigService
  ) {
  }
}
