<div matDialogTitle>Radera</div>
<div matDialogContent>
  <p>
    Är du säker på att du vill radera {{ data.roleName }}?
  </p>
  <div matDialogActions>
    <button
      (click)="delete()"
      color="primary"
      mat-raised-button>Ja
    </button>
    <button [mat-dialog-close]="undefined" mat-raised-button>Avbryt</button>
  </div>
</div>
