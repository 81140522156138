<div class="text-container">
  <h1>Login</h1>
  <p>Välkommen till Behörighetshantering, här hanteras behörighet för samtliga
    system som är framtagna via Linaf.</p>
  <spb-bankid
    (accessToken)="configService.setToken($event).subscribe()"
    [bankIdUrl]="bankIdUrl"
    mode="login"
    [domain]="domain"></spb-bankid>
</div>
