import {SpbUserWithRoles} from './services/user.service'

export const newSpbUser: SpbUserWithRoles = {
  acc: 0,
  dmn: '',
  email: '',
  exp: 0,
  iat: 0,
  itemId: '',
  name: '',
  office: '',
  pet: '',
  phone: '',
  ref: '',
  roles: [],
  sId: '',
  signed: '',
  sub: '',
  userType: '',
  version: 0,
  rolesHeld: [],
  rolesNotHeld: []
}

export type SiteKey = string
export type RoleKey = string

export interface SitePermissions {
  name: string
  roleKeys: RoleKey[]
}

export interface UserTypeOptions {
  /**
   * The value of the user type.
   */
  value: string

  /**
   * The name of the user type to display.
   */
  name: string
}

/**
 * The different types of user that could be added
 */
export const userTypeOptions: UserTypeOptions[] = [
  {value: 'employee', name: 'Bankanställd'},
  {value: 'support', name: 'Support (H1)'},
]

/**
 * This is the timer used for closing the snackbar for saved/deleted roles/users.
 */
export const TIME_OUT = 3000

/**
 * Describes a log
 */
export interface SpbLog {
  action: string
  id?: string
  timeStamp: number
  loggedItem: {
    userSub: string
    pre: any
    post: any
  }
}

/**
 * Change name in logs details template
 */
export const changeName: Record<string, string> = {
  addedRole: 'Adderad Roll',
  name: 'Namn',
  email: 'Email',
  deletedRole: 'Raderad Roll',
  roleDescription: 'Beskrivning av roll',
  roleName: 'Rollens Namn',
  sub: 'Personnummer',
  phone: 'Telefon',
  pet: 'Husdjur',
  office: 'Kontor',
  userType: 'Användartyp',
  key: 'Kod',
  roleKey: 'Rollens Kod',
  siteName: 'Sidans Namn',
  siteKey: 'Sidans Kod',
  roles: 'Behörigheter'
}



