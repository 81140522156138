import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {environment} from '../environments/environment'
import {SpbLog} from './types'

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(private httpClient: HttpClient) {
  }

  /**
   * get all logs from database
   */
  public getAllLogs(timeStamp: number): Observable<SpbLog[]> {
    const url = `${environment.authServiceUrl}/logs/users?showItemsAfterThisTimestamp=${timeStamp}`
    return this.httpClient.get<SpbLog[]>(url)
  }
}
