import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {map, Observable} from 'rxjs'
import {Role} from 'sparbanken-syd-auth-backend'
import {environment} from '../../environments/environment'
import {ServiceBase, Wait} from './service-base.class'


@Injectable({
  providedIn: 'root'
})
export class AdminService extends ServiceBase {

  constructor(private readonly http: HttpClient) {
    super()
  }


  /**
   * Save a role to database
   */
  @Wait('Sparar', 'Sparar roll...')
  public putRole(role: any): Observable<any> {
    const url = `${environment.userAdminApi}/roles`
    return this.http.put<Role>(url, role)
  }

  /**
   * Save a role to database
   */
  @Wait('Hämtar', 'Hämtar roller...')
  public getRoles(): Observable<Role[]> {
    const url = `${environment.userAdminApi}/roles`
    return this.http.get<Role[]>(url).pipe(
      map(roles => roles.sort((a, b) => a.siteName!.localeCompare(b.siteName!)))
    )
  }

  /**
   * Delete a role
   */
  @Wait('Raderar', 'Raderar roll...')
  public deleteRole(role: Role): Observable<void> {
    const url = `${environment.userAdminApi}/roles/${role.id}`
    return this.http.delete<void>(url, {body: role})
  }
}
