import {JsonPipe} from '@angular/common'
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {FormGroup} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader
} from '@angular/material/expansion'
import {MatListOption, MatSelectionList} from '@angular/material/list'
import {MatSnackBar} from '@angular/material/snack-bar'
import {MatTooltip} from '@angular/material/tooltip'
import {environment} from '../../../environments/environment'
import {RoleInfo, UserService} from '../../services/user.service'
import {TIME_OUT} from '../../types'

@Component({
  selector: 'spb-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss'],
  standalone: true,
  imports: [MatButton, MatSelectionList, MatExpansionPanel, MatExpansionPanelHeader, MatListOption, MatTooltip, MatAccordion, JsonPipe]
})
export class UserRoleComponent implements OnInit {
  @Input() userForm!: FormGroup
  @Output() resetUserValues = new EventEmitter<boolean>()
  public panelDelay: boolean = false // Else the panels will start being expanded

  protected readonly environment = environment

  constructor(
    protected userService: UserService,
    private readonly snackBar: MatSnackBar
  ) {
  }

  get rolesHeldArray() {
    return this.getRolesArray('rolesHeld')
  }

  get rolesNotHeldArray() {
    return this.getRolesArray('rolesNotHeld')
  }

  public ngOnInit(): void {
    setTimeout(() => this.panelDelay = true, 0)
  }


// Remove many (checked) selected roles
  public removeSelectedRoles(selectedOptions: MatListOption[]): void {
    selectedOptions.forEach((option) => {
      const roleToRemove = option.value
      this.moveRoleBetweenLists(roleToRemove, 'rolesHeld', 'rolesNotHeld')
      this.removeRoleFromUser(roleToRemove.roleKey)
    })

    // Remove 'userDocUser' role if neither 'admin' nor 'loanPromiseReadOnly' is present
    const userRoles = this.userService.user$().roles
    if (!userRoles.includes('admin') && !userRoles.includes('loanPromiseReadOnly')) {
      this.ensureRoleRemoved('userDocUser')
    }
  }

  // Add many (checked) selected roles
  public addSelectedRoles(selectedOptions: MatListOption[]): void {
    selectedOptions.forEach((option) => {
      const roleToAdd = option.value
      this.moveRoleBetweenLists(roleToAdd, 'rolesNotHeld', 'rolesHeld')
      this.addRoleToUser(roleToAdd.roleKey)
    })

    // Ensure 'employee' role is assigned if user is an employee
    if (this.userService.user$().userType === 'employee') {
      this.ensureRoleAssigned('employee')
    }

    // Ensure 'userDocUser' role is assigned if the user is an admin or has 'loanPromiseReadOnly' role
    const userRoles = this.userService.user$().roles
    if (userRoles.includes('admin') || userRoles.includes('loanPromiseReadOnly')) {
      this.ensureRoleAssigned('userDocUser')
    }
  }

  // Remove a role from the user and update the permissions map
  public removeRoleFromUser(roleKey: string): void {
    // Find the index of the role to be removed
    const index = this.userService.user$().roles.indexOf(roleKey)

    if (index !== -1) {
      // Remove the role from the user's roles array
      this.userService.user$().roles.splice(index, 1)
    }
  }

  // Add a role to the user and update the permissions map
  public addRoleToUser(roleKey: string): void {
    // Check if the user already has the role
    if (!this.userService.user$().roles.includes(roleKey)) {
      // Add the role to the user's roles array
      this.userService.user$().roles.push(roleKey)
    } else {
      console.error(`User already has role with key ${roleKey}`)
    }
  }

  public saveUser(): void {
    this.userService.saveUser(this.userService.user$()).subscribe({
      next: () => {
        this.snackBar
          .open((this.userService.user$().name || 'Användaren') + ' har sparats', '', {
            duration: TIME_OUT
          })
        this.resetUser()
      }
    })
  }

  public resetUser() {
    this.resetUserValues.emit(true)
  }

  /**
   * To avoid change after check...
   * @param index
   * @param roleKey
   * @protected
   */
  public trackRoleKey(index: number, roleKey: string) {
    return `${index}-${roleKey}` // This will return a unique string for each item
  }

  private getRolesArray(roleType: 'rolesHeld' | 'rolesNotHeld') {
    const user = this.userService.user$()
    const roles = user[roleType]

    const entries = Object.entries(roles)
      .map(([siteKey, site]) => ({
        siteKey: siteKey,
        siteName: site.siteName,
        roles: site.roles
      }))

    const general = entries.find(entry => entry.siteKey === 'general')
    const otherEntries = entries.filter(entry => entry.siteKey !== 'general')

    return general ? [general, ...otherEntries] : otherEntries
  }

  private ensureRoleAssigned(roleKey: string): void {
    const userRoles = this.userService.user$().roles
    if (!userRoles.includes(roleKey)) {
      this.addRoleToUser(roleKey)
    }
  }

  private ensureRoleRemoved(roleKey: string): void {
    const userRoles = this.userService.user$().roles
    if (userRoles.includes(roleKey)) {
      this.removeRoleFromUser(roleKey)
    }
  }
  private moveRoleBetweenLists(
    role: RoleInfo,
    sourceList: 'rolesHeld' | 'rolesNotHeld',
    targetList: 'rolesHeld' | 'rolesNotHeld'
  ): void {
    const sourceRoles = this.userService.user$()[sourceList][role.siteKey].roles
    const targetRoles = this.userService.user$()[targetList][role.siteKey].roles

    const roleIndex = sourceRoles.findIndex((r) => r.roleKey === role.roleKey)
    if (roleIndex !== -1) {
      targetRoles.push(role)
      sourceRoles.splice(roleIndex, 1)
    }
  }

}
