import {CdkTextareaAutosize} from '@angular/cdk/text-field'
import {Component, Inject, OnInit} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {Role} from 'sparbanken-syd-auth-backend'


@Component({
  selector: 'spb-edit-role',
  standalone: true,
  imports: [
    CdkTextareaAutosize,
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle
  ],
  templateUrl: './edit-role.component.html',
  styleUrl: './edit-role.component.scss'
})
export class EditRoleComponent implements OnInit {
  public roleForm: FormGroup = new FormGroup({
    id: new FormControl<string>(''),
    roleDescription: new FormControl<string>('', [Validators.required]),
    roleKey: new FormControl<string>('', [Validators.required]),
    roleName: new FormControl<string>('', [Validators.required]),
    siteKey: new FormControl<string>('', [Validators.required]),
    siteName: new FormControl<string>('', [Validators.required])
  })


  constructor(@Inject(MAT_DIALOG_DATA)
              public data: Role) {
  }

  public ngOnInit() {
    this.roleForm.patchValue(this.data)
  }
}
