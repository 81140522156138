<div matDialogTitle>Ändringar</div>
<div matDialogContent>
  <div class="title">{{ data.action }}</div>

  @if (beforeChanges.length > 0) {
    <div class="title">Ändringar från</div>
  }

  @for (before of beforeChanges; track $index) {
    <div>
      @for (change of before | keyvalue; track $index) {
        <p>{{ showDifferentKeyName(change.key) }}: {{ change.value }}</p>
      }
    </div>
  }


  @if (afterChanges.length > 0) {
    <div class="title">Ändringar gjorda till</div>
  }

  @for (after of afterChanges; track $index) {
    <div>
      @for (change of after | keyvalue; track $index) {
        <p>{{ showDifferentKeyName(change.key) }}: {{ change.value }}</p>
      }
    </div>
  }


  @if (roleChanges.length > 0) {
    <div class="title">Förändring av behörigheter</div>
  }

  @for (role of roleChanges; track $index) {
    <div>
      @for (change of role | keyvalue; track $index) {
        <p>{{ showDifferentKeyName(change.key) }}: {{ change.value }}</p>
      }
    </div>
  }

  <div matDialogActions>
    <button [mat-dialog-close]="undefined" mat-raised-button>Stäng</button>
  </div>
</div>
