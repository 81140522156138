<div class="create-user-holder">
  @if (!create) {
    <button mat-raised-button (click)="createUser()">Skapa användare</button>
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Sök användare</mat-label>
      <input
        matInput
        placeholder="Fritext"
        [formControl]="search">
    </mat-form-field>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <mat-label>Välj kontor</mat-label>
      <mat-select
        [formControl]="officeSelector"
        (selectionChange)="changeOffice($event.value)">
        <mat-option [value]="''">Samtliga kontor</mat-option>
        @for (office of userService.offices$(); track $index) {
          <mat-option
            [value]="office">{{ office }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field [subscriptSizing]="'dynamic'">
      <mat-label>Välj användartyp</mat-label>
      <mat-select  [formControl]="userTypeSelector" (selectionChange)="changeUserType($event.value)">
        <mat-option [value]="''">Samtliga</mat-option>
        <mat-option [value]="'employee'">Bankanställda</mat-option>
        <mat-option [value]="'support'">Support (H1)</mat-option>
      </mat-select>
    </mat-form-field>

  } @else {
    <spb-user [user]="newUser"
              [userForm]="userForm"
    ></spb-user>
    @if (userService.user$().sub) {
      <spb-user-role
        [userForm]="userForm"
        (resetUserValues)="userIsCreated()"
      ></spb-user-role>
    }
  }
</div>
@if (!userService.user$().sub && this.create) {
  <button mat-raised-button (click)="this.create = false"
          [style.margin]="'-30px 0 30px 0'">Avbryt
  </button>
}

<table matSort mat-table [dataSource]="dataSource"
       multiTemplateDataRows>
  @for (column of columnsToDisplay; track column) {
    <ng-container matColumnDef="{{column}}">
      <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column"
      > {{ columnTitles[column] }}
      </th>
      @if (column === 'deleteUser') {
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            (click)="deleteUser(element)"
            (keydown.enter)="deleteUser(element)"
            class="delete-icon">
            <span class="material-symbols-outlined">delete</span>
          </button>


        </td>
      } @else {
        <td mat-cell *matCellDef="let element"
            (click)="expand(element)"
            (keydown.enter)="expand(element)"
            class="pointer"
            tabindex="0">
  <span>
    {{ element[column] }}
  </span>
        </td>

      }
    </ng-container>
  }

  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="expand row" (click)="expand(element)">
        @if (userService.userExpandedElement$() === element) {
          <mat-icon>keyboard_arrow_up</mat-icon>
        } @else {
          <mat-icon>keyboard_arrow_down</mat-icon>
        }
      </button>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element"
        [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="element-detail"
           [@detailExpand]="element === userService.userExpandedElement$() ? 'expanded' : 'collapsed'">
        <div class="expanded-user">
          @if (userService.userExpandedElement$() === element) {
            <div class="user-holder">
              <spb-user [user]="element"
                        [userForm]="userForm"
              ></spb-user>
              <spb-user-role
                [userForm]="userForm"
                (resetUserValues)="userIsCreated()"
              ></spb-user-role>
            </div>
          }
        </div>
        <div class="element-description">
          {{ element.description }}
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
      class="element-row"
      [class.expanded-row]="userService.userExpandedElement$() === element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
      class="detail-row"></tr>
</table>
