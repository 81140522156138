import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatOption, MatSelect} from '@angular/material/select'
import {verify} from '@sparbanken-syd/personnummer'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {Subscription} from 'rxjs'
import {SpbUserWithRoles, UserService} from '../../services/user.service'
import {newSpbUser, userTypeOptions} from '../../types'

@Component({
    selector: 'spb-user',
    templateUrl: './user.component.html',
    styleUrl: './user.component.scss',
    standalone: true,
    imports: [ReactiveFormsModule, MatFormField, MatLabel, MatSelect, MatOption, MatInput, ThemeModule, MatError]
})
export class UserComponent implements OnInit, OnDestroy {
  @Input() user!: SpbUserWithRoles
  @Input() userForm!: FormGroup
  @Output() resetUserValues = new EventEmitter<boolean>()

  protected readonly userTypeOptions = userTypeOptions
  private userTypeSubscription: Subscription = new Subscription()
  private nameSubscription: Subscription = new Subscription()
  private formChangesSubscription: Subscription = new Subscription()

  constructor(
    protected userService: UserService
  ) {
  }

  public ngOnInit(): void {
    this.userTypeSubscription = this.userForm.controls.userType.valueChanges
      .subscribe(() => {

        const employee = this.user.userType === 'employee' || this.userForm.controls.userType.value === 'employee'

        if (employee) {
          // Use standard userForm
          this.userForm.addControl('office', new FormControl<string>('', [Validators.required]))
          this.userForm.addControl('phone', new FormControl<string>(''))
          this.userForm.addControl('email', new FormControl<string>('', [Validators.required, Validators.email]))
          this.userForm.addControl('sId', new FormControl<string>('', [Validators.required, Validators.pattern('s\\d{6}')]))
        } else {
          // Remove unnecessary controls and validators
          this.userForm.removeControl('office')
          this.userForm.removeControl('phone')
          this.userForm.removeControl('email')
          this.userForm.removeControl('sId')
        }
      })
    this.initializeUserForm()
    this.subscribeToFormChanges()
  }


  /**
   * Converts a Swedish name to a standardized email address format.
   * Replaces special characters with their English equivalents and appends '@sparbankensyd.se'.
   */
  /**
   * Converts a Swedish name to a standardized email address format.
   * Replaces special characters with their English equivalents and appends '@sparbankensyd.se'.
   */
  public nameToEmail(): void {
    const emailAdress = this.userForm.controls.name.value
        .toLocaleLowerCase()
        .trim()
        .replace(' ', '.')
        .replace('ä', 'ae')  // Replace ä with ae
        .replace('å', 'aa')  // Replace å with aa
        .replace('ö', 'oe')  // Replace ö with oe
        .replace('ü', 'ue')  // Replace ü with ue
        .replace('é', 'e')   // Replace é with e
        .replace('è', 'e')   // Replace è with e
        .replace('ë', 'e')   // Replace ë with e
        .replace('ï', 'i')   // Replace ï with i
        .replace('î', 'i')   // Replace î with i
        .replace('ì', 'i')   // Replace ì with i
        .replace('í', 'i')   // Replace í with i
        .replace('ù', 'u')   // Replace ù with u
        .replace('û', 'u')   // Replace û with u
      + '@sparbankensyd.se'

    this.userForm.controls.email.setValue(emailAdress)
  }


  public ngOnDestroy(): void {
    this.userTypeSubscription.unsubscribe()
    this.nameSubscription.unsubscribe()
    this.formChangesSubscription.unsubscribe()
  }

  /**
   * Initializes the user form when we have an existing user (with itemId)
   * Regardless of the itemId, the user object is set in the user service.
   */
  private initializeUserForm(): void {
    newSpbUser.roles = []
    this.userForm.reset(newSpbUser)
    if (this.user.itemId) {
      this.userForm.patchValue(this.user)
      this.userForm.controls.userType.disable()
      this.userForm.controls.sub.disable()
    } else {
      // Toggle selectUserType and sub based on user.itemId existence
      this.userForm.controls.userType.enable()
      this.userForm.controls.sub.enable()
    }

    this.userService.user$.set(this.user) // The only place where the user should be initialized!
  }


  /**
   * Subscribes to changes in the form values.
   * Whenever the form values change, the user object in the user service is updated with the new values.
   */
  private subscribeToFormChanges(): void {
    this.formChangesSubscription = this.userForm.valueChanges.subscribe(() => {
      const newUser = this.userForm.getRawValue()
      try {
        newUser.sub = verify(newUser.sub)[12] // Make sure sub is correctly formatted
      } catch {
        // Do nothing
      }
      this.userService.user$.set(newUser) //The only place where the user should be changed!
    })
  }
}
