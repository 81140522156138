import {Component, Inject, OnInit} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog'
import {changeName, SpbLog} from '../../types'
import { KeyValuePipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    selector: 'spb-logs-details',
    templateUrl: './logs-details.component.html',
    styleUrls: ['./logs-details.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, MatButton, MatDialogClose, KeyValuePipe]
})
export class LogsDetailsComponent implements OnInit {
  public beforeChanges: { [key: string]: string | string[] }[] = []
  public afterChanges: { [key: string]: string | string[] }[] = []
  public roleChanges: { [key: string]: string | string[] }[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: SpbLog
  ) {
  }

  ngOnInit(): void {
    this.checkForChanges()
  }

  /**
   * This is (maybe not so nice) all in one function (sorry if it's hard to understand)
   */
  checkForChanges() {
    const preObject: any = this.data.loggedItem.pre
    const postObject: any = this.data.loggedItem.post

    /**
     * first row is from user object and second row is from role object
     */
    const keysToCompare = [
      'sId', 'sub', 'name', 'phone', 'pet', 'office', 'email', 'userType',
      'key', 'roleDescription', 'roleKey', 'roleName', 'siteKey', 'siteName', 'roles']

    /**
     * First check if there is both a preObject and a postObject, and check that each of them has the key to compare
     * If the compared object key pairs is not the same, then push them to respective array, that is going to be
     * shown in the template
     */
    keysToCompare.forEach(each => {
      if (preObject && postObject &&
        postObject[each] &&
        preObject[each] !== postObject[each]) {

        /**
         * an empty siteKey is the same as "Generell"
         */
        if (postObject.siteKey === '') {
          postObject.siteKey = 'Generell'
        }

        this.beforeChanges.push({[each]: preObject[each]})
        this.afterChanges.push({[each]: postObject[each]})
      }
    })
    /**
     * Roles are handled pretty much the same, but differently because they are in a array of strings
     */
    if (preObject && postObject &&
      preObject.roles && postObject.roles &&
      preObject.roles.length !== postObject.roles.length) {
      /**
       * If a role is deleted
       */
      preObject.roles.forEach((each: string) => {
        if (!postObject.roles.includes(each)) {
          this.roleChanges.push({deletedRole: each})
        }
      })

      /**
       * If a role is added
       */
      postObject.roles.forEach((each: string) => {
        if (!preObject.roles.includes(each)) {
          this.roleChanges.push({addedRole: each})
        }
      })
    }
  }

  /**
   * Show the changed keys with Swedish names
   *
   * @param key
   */
  showDifferentKeyName(key: string) {
    if (changeName[key]) {
      return changeName[key]
    }
    return key
  }
}
