import {animate, state, style, transition, trigger} from '@angular/animations'
import {AfterViewInit, Component, effect, ViewChild} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatButton, MatIconButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatOption, MatSelect} from '@angular/material/select'
import {MatSnackBar} from '@angular/material/snack-bar'
import {MatSort, MatSortHeader} from '@angular/material/sort'
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table'
import {filter, startWith, switchMap} from 'rxjs'
import {Role, SpbUser} from 'sparbanken-syd-auth-backend'
import {UserService} from '../../services/user.service'
import {newSpbUser, TIME_OUT} from '../../types'
import {
  ConfirmDeleteDialogComponent
} from '../confirm-delete-dialog/confirm-delete-dialog.component'
import {
  NotSameWarningComponent
} from '../not-same-warning/not-same-warning.component'
import {UserRoleComponent} from '../user-role/user-role.component'
import {UserComponent} from '../user/user.component'

@Component({
  selector: 'spb-users-list',
  templateUrl: './users-list.component.html',
  styleUrl: './users-list.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  standalone: true,
  imports:
    [MatButton,
      MatFormField,
      MatLabel,
      MatInput,
      ReactiveFormsModule,
      MatSelect,
      MatOption, UserComponent, UserRoleComponent, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatIconButton, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow]
})
export class UsersListComponent implements AfterViewInit {
  @ViewChild(MatSort) sort = new MatSort()

  // Defining column titles for display
  public columnTitles = {
    sub: 'Personnummer',
    name: 'Namn',
    office: 'Kontor',
    deleteUser: 'Radera'
  }

  public dataSource = new MatTableDataSource<SpbUser>([])

  // Array of column names to display
  public columnsToDisplay: string[] = ['sub', 'name', 'office', 'deleteUser']
  public columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand']

  public search = new FormControl<string>('', {nonNullable: true})

  // Original user object before expansion
  public originalUser = ''

  public create = false

  public newUser = newSpbUser

  public userForm: FormGroup = new FormGroup({
    itemId: new FormControl<string>(''),
    userType: new FormControl<string>('', [Validators.required]),
    name: new FormControl<string>('', [Validators.required]),
    office: new FormControl<string>('', [Validators.required]),
    phone: new FormControl<string>(''),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    roles: new FormControl<Role[]>([]),
    sId: new FormControl<string>('', [Validators.required, Validators.pattern('s\\d{6}')]),
    sub: new FormControl<string>('', [Validators.required, this.userService.userExistValidator()]),
    timeStamp: new FormControl(''),
    version: new FormControl(0)
  })

  public officeSelector = new FormControl<string | null>(null)
  public userTypeSelector = new FormControl<string | null>(null)


  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    protected userService: UserService
  ) {
    effect(() => {
      this.dataSource.data = userService.users$()
    })
  }

  /**
   * Expands or collapses an element based on its current state.
   * If the element is being expanded, it stores the original user object.
   * Otherwise, it compares the edited user with the original and toggles the expanded element accordingly.
   */
  public expand(element: SpbUser) {
    this.create = false // We never create a new user when we expand!
    /**
     * Compares the edited user with the original user.
     * If they differ, it prompts the user with a warning dialog.
     * We only show warning if we have an originalUser!
     */
    if (
      this.originalUser &&
      (this.originalUser !== JSON.stringify(this.userService.user$()))) {
      this.showWarning(element)
    } else {
      this.originalUser = JSON.stringify(element)
      this.toggleElement(element)
    }
  }

  public createUser() {
    // TODO: Inititate rolesNotHeld on this.newUSer
    this.create = true
    this.resetUser()
  }

  public deleteUser(user: SpbUser): void {
    this.dialog.open(ConfirmDeleteDialogComponent, {data: user})
      .afterClosed()
      .pipe(
        filter(Boolean), // Ensure confirmed is true
        switchMap(() => this.userService.deleteUser(user)),
        switchMap(() => {
          this.snackBar.open(user.name + ' har raderats', '', {
            duration: TIME_OUT + 2000
          })
          this.create = false // Prevent creating while deleting
          return this.userService.getUsers()
        })
      )
      .subscribe()
  }


  /**
   * Don't show created user after it is created
   */
  public userIsCreated() {
    this.create = false
    this.resetUser()
  }

  /**
   * Reset the original user, since we don't want to compare again
   */
  public resetUser() {
    this.originalUser = ''
    this.userForm.patchValue(newSpbUser)
    this.userService.user$.set(newSpbUser)
    this.userService.userExpandedElement$.set(null)
  }

  public changeFilter(value: string, property: 'office' | 'userType') {
    const selector = property === 'userType' ? this.officeSelector : this.userTypeSelector
    selector.reset()
    this.search.reset()

    const originalFilterPredicate = this.dataSource.filterPredicate

    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data[property]?.toLowerCase() === filter?.toLowerCase()
    }

    this.dataSource.filter = value.trim().toLowerCase()
    this.dataSource.filterPredicate = originalFilterPredicate
  }

  public changeOffice(value: string) {
    this.changeFilter(value, 'office')
  }

  public changeUserType(value: string) {
    this.changeFilter(value, 'userType')
  }


  public ngAfterViewInit(): void {
    this.dataSource.data = this.userService.users$()
    this.dataSource.sort = this.sort
    this.search.valueChanges.pipe(
      startWith('')
    ).subscribe({
      next: (value: string) =>
        this.dataSource.filter = value.trim().toLowerCase()
    })
  }

  /**
   * Toggles the expanded state of the given element.
   * If the element is already expanded, it collapses it; otherwise, it expands it.
   */
  private toggleElement(editedUser: SpbUser) {
    this.userService.userExpandedElement$.set(this.userService.userExpandedElement$() === editedUser ? null : editedUser)
  }

  /**
   * Depending on the user's response, it either saves the edited user
   * or restores the original user in the users array, effectively undoing the edit.
   * This method assumes that `userService.users$()` returns the actual array of users.
   * @private
   */
  private showWarning(editedUser: SpbUser) {
    // Open a dialog to warn the user about the difference
    this.dialog.open(NotSameWarningComponent).afterClosed().subscribe({
      next: saved => {
        if (saved) {
          // User confirmed to proceed with the edited user
          this.userService.saveUser(this.userService.user$()).subscribe({
            next: () => this.snackBar
              .open((this.userService.user$().name || 'Användaren') + ' har sparats', '', {
                duration: TIME_OUT
              })
          })
        } else {
          // User chose to undo the changes, restoring the original user
          const originalUserData = JSON.parse(this.originalUser)
          const users = this.userService.users$()
          const userIndex = users.findIndex(u => u.itemId === originalUserData.itemId)

          if (userIndex !== -1) {
            // Update the user in the array with the original user data
            Object.assign(users[userIndex], originalUserData)
          }

        }
        // Toggle after we have shown the warning
        this.toggleElement(editedUser)
        // // Reset the original user, since we don't want to compare again
        this.resetUser()
        // this.originalUser = ''
      }
    })
  }
}
