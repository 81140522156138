import {animate, state, style, transition, trigger} from '@angular/animations'
import {KeyValuePipe} from '@angular/common'
import {Component} from '@angular/core'
import {
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion'
import {MatSelectionList} from '@angular/material/list'
import {Role} from 'sparbanken-syd-auth-backend'
import {RoleService} from '../../services/role.service'
import {RoleComponent} from '../role/role.component'

@Component({
  selector: 'spb-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrl: './roles-list.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  standalone: true,
  imports: [MatSelectionList, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, RoleComponent, KeyValuePipe]
})
export class RolesListComponent {
  public role: Role | undefined = undefined
  public activePanel: string | null = null
  public selectedRole: Role | null = null

  constructor(protected roleService: RoleService) {
  }

  public findRole(roleKey: string): void {
    const roleMap = this.roleService.roleMap$()
    for (const siteKey in roleMap) {
      const role = roleMap[siteKey].roles.find(r => r.roleKey === roleKey)
      if (role) {
        this.selectedRole = role
        break
      }
    }
  }
}
