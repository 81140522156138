import {Component} from '@angular/core'
import version from '../assets/package.json'
import {ConfigService} from './services/config.service'
import { AsyncPipe } from '@angular/common';
import { ThemeModule } from '@sparbanken-syd/sparbanken-syd-theme';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';

@Component({
    selector: 'spb-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, ThemeModule, AsyncPipe]
})
export class AppComponent {
  public version = version.version

  constructor(public configService: ConfigService) {
  }
}
