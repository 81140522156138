<div class="role-holder">
  <div class="column">

    <div class="role-title">
      @if (userRoleList?.selectedOptions?.hasValue()) {
        <button mat-raised-button color="warn"
                (click)="removeSelectedRoles(userRoleList.selectedOptions.selected)">
          Ta bort valda behörigheter
        </button>
      } @else {
        <div>Användarens behörigheter</div>
      }
    </div>

    <mat-selection-list #userRoleList color="primary">
      @for (site of rolesHeldArray; track site.siteKey) {
        @if (site.roles.length > 0  && panelDelay) {
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              {{ site.siteName }}
            </mat-expansion-panel-header>

            <div class="site-holder">
              @for (role of site.roles; track role.roleKey) {
                <mat-list-option [value]="role">
                  <span matTooltip="{{ role.roleDescription }}" matTooltipPosition="above">{{ role.roleName }}</span>
                </mat-list-option>
              }
            </div>
          </mat-expansion-panel>
        }
      } @empty {
        <div>Inga tjänster tillgängliga</div>
      }
    </mat-selection-list>
  </div>

  <div class="column">
    <div class="role-title">
      @if (availableRoleList?.selectedOptions?.hasValue()) {
        <button mat-raised-button color="primary"
                (click)="addSelectedRoles(availableRoleList.selectedOptions.selected)">
          Lägg till valda behörigheter
        </button>
      } @else {
        <div>Välj behörigheter att lägga till</div>
      }
    </div>

    <mat-selection-list #availableRoleList color="primary">
      @for (site of rolesNotHeldArray; track site.siteKey) {
        @if (site.roles.length > 0 && panelDelay) {
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              {{ site.siteName }}
            </mat-expansion-panel-header>

            <div class="site-holder">
              @for (role of site.roles; track role.roleKey) {
                <mat-list-option [value]="role">
                  <span matTooltip="{{ role.roleDescription }}" matTooltipPosition="above">{{ role.roleName }}</span>
                </mat-list-option>
              }
            </div>
          </mat-expansion-panel>
        }
      } @empty {
        <div>Inga tjänster tillgängliga</div>
      }
    </mat-selection-list>
  </div>

  <div class="buttons-holder">
    @if (!userForm.value.itemId) {
      <button (click)="resetUser()" mat-raised-button type="button">Avbryt</button>
    }
    <button (click)="saveUser()" [disabled]="!userForm.valid" color="primary" mat-raised-button type="button">Spara</button>
  </div>
</div>
