import {Component, Input, OnInit} from '@angular/core'
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms'
import {MatSnackBar} from '@angular/material/snack-bar'
import {Role} from 'sparbanken-syd-auth-backend'
import {RoleService} from '../../services/role.service'
import {TIME_OUT} from '../../types'
import { MatButton } from '@angular/material/button';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'spb-role',
    templateUrl: './role.component.html',
    styleUrl: './role.component.scss',
    standalone: true,
    imports: [ReactiveFormsModule, MatFormField, MatLabel, MatInput, CdkTextareaAutosize, MatButton]
})
export class RoleComponent implements OnInit {
  @Input() role!: Role

  public roleForm: FormGroup = new FormGroup({
    id: new FormControl<string>(''),
    roleDescription: new FormControl<string>('', [Validators.required]),
    roleKey: new FormControl<string>(''),
    roleName: new FormControl<string>('', [Validators.required]),
    siteKey: new FormControl<string>(''),
    siteName: new FormControl<string>(''),
  })

  constructor(
    private roleService: RoleService,
    private snackBar: MatSnackBar
  ) {
  }

  public ngOnInit() {
    this.roleForm.patchValue(this.role)
  }

  public updateRole() {
    this.roleService.updateRole(this.roleForm.value).subscribe({
      next: () => {
        this.roleService.roleExpandedElement$.set(null)
        this.snackBar.open('Rollen har uppdaterats', '', {
          duration: TIME_OUT
        })
      }
    })
  }
}
