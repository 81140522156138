import {Component} from '@angular/core'
import { TMode, BankIdModule } from '@sparbanken-syd/sparbanken-syd-bankid'
import {environment} from '../../../environments/environment'
import {ConfigService} from '../../services/config.service'

@Component({
    selector: 'spb-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    standalone: true,
    imports: [BankIdModule]
})
export class LoginComponent {

  public bankIdUrl = environment.authServiceUrl
  public domain = environment.domain
  public mode:TMode = 'login'
  constructor(
    public configService: ConfigService
  ) {
  }
}
