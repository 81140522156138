<div mat-dialog-title>Ta bort</div>

<div class="dialog-content-holder">
  <span class="delete-title">
    Är du säker på att du vill ta bort '{{ user.name }}'?
  </span>

  <span class="delete-warning">OBS! Åtgärden kan inte ångras</span>
</div>

<mat-dialog-actions class="dialog-actions-holder">
  <button mat-raised-button mat-dialog-close="">Nej</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">
    Ja
  </button>
</mat-dialog-actions>




