import {Component} from '@angular/core'
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog'
import { MatButton } from '@angular/material/button';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    selector: 'spb-not-same-warning',
    templateUrl: './not-same-warning.component.html',
    styleUrl: './not-same-warning.component.scss',
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, MatButton]
})
export class NotSameWarningComponent {

  constructor(
    public dialogRef: MatDialogRef<NotSameWarningComponent>
  ) {
  }

  public save() {
    this.dialogRef.close(true)
  }

  public abort() {
    this.dialogRef.close(false)
  }

}
