import {Component, OnInit} from '@angular/core'
import { MatDatepickerInputEvent, MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker'
import {LogsService} from '../logs.service'
import {SpbLog} from '../types'
import { LogsListComponent } from './logs-list/logs-list.component';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'spb-logs',
    templateUrl: './logs.component.html',
    styleUrl: './logs.component.scss',
    standalone: true,
    imports: [MatFormField, MatLabel, MatInput, MatDatepickerInput, MatDatepickerToggle, MatDatepicker, MatButton, LogsListComponent]
})
export class LogsComponent implements OnInit {
  /**
   * array with logs from spb-auth-backend
   */
  public spbLogs: SpbLog[] = []

  /**
   * variable for logging
   */
  public dateInTemplate: Date = new Date(Date.now() - 604800000) //one week ago

  /**
   * Timestamp for logs
   */
  public timeStamp: number = new Date().getTime() - 604800000 //one week ago

  constructor(private logsService: LogsService) {
  }

  public ngOnInit() {
    this.getAllLogs(this.timeStamp)
  }

  /**
   * Update logs with a button
   */
  public updateLogs() {
    this.getAllLogs(this.timeStamp)
  }


  /**
   * input a date in the date picker
   */
  public inputDate(event: MatDatepickerInputEvent<any>) {
    this.timeStamp = new Date(event.value).getTime()
  }

  /**
   * get all logs from spb-auth-backend
   */
  private getAllLogs(timeStamp: number) {
    this.logsService.getAllLogs(timeStamp).subscribe({
      next: (logs: SpbLog[]) => {
        logs.sort(((a: SpbLog, b: SpbLog) => b.timeStamp - a.timeStamp))
        this.spbLogs = logs
      }
    })
  }
}
