<div class="roles-list-holder">
  <div class="header">
    <h3>Behörigheter</h3>
    <p class="info-text">
      Välj en tjänst för att visa detaljerad information och
      uppdatera namn samt beskrivning för varje behörighet i applikationen.
    </p>
  </div>
  <mat-selection-list color="primary">
    @for (site of roleService.roleMap$(); track site.siteName) {
      <mat-expansion-panel [expanded]="activePanel === site.siteName"
                           (opened)="activePanel = site.siteName">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ site.siteName }}</mat-panel-title>
          <mat-panel-description>
            {{ site.roles.length }} behörigheter tillgängliga
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="roles">
          @for (role of site.roles; track role.roleKey) {
            <button (click)="findRole(role.roleKey)" class="role">
              {{ role.roleName }}
            </button>
            @if (selectedRole && selectedRole.roleKey === role.roleKey) {
              <spb-role [role]="selectedRole"></spb-role>
            }
          }
        </div>
      </mat-expansion-panel>
    } @empty {
      <p>Inga roller tillgängliga.</p>
    }
  </mat-selection-list>
</div>
