<form [formGroup]="userForm">

  <div>
    <mat-form-field>
      <mat-label>Välj användartyp</mat-label>
      <mat-select formControlName="userType" ngDefaultControl>
        @for (userTypeOption of userTypeOptions; track $index) {
          <mat-option [value]="userTypeOption.value">
            {{ userTypeOption.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>Personnummer</mat-label>
      <input spbPersonnummerValidator formControlName="sub" matInput
             type="text">

      <mat-error
        [style.width.px]="500">
        {{ userForm.controls.sub.errors?.error }}
        @if (userForm.controls.sub.errors?.['exists']) {
          Personnumret finns i databasen
        }
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Namn</mat-label>
      <input formControlName="name" matInput placeholder="Förnamn Efternamn"
             type="text"
             (blur)="this.userForm.controls.userType.value === 'employee' && nameToEmail()">
    </mat-form-field>
  </div>

  @if (userForm.controls.userType.value === 'employee') {
    <div>
      <mat-form-field>
        <mat-label>Telefon</mat-label>
        <input formControlName="phone" matInput type="text">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Kontor</mat-label>
        <mat-select formControlName="office" ngDefaultControl>
          @for (office of userService.offices$(); track $index) {
            <mat-option
              [value]="office">{{ office }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label>S-ID</mat-label>
        <input formControlName="sId" matInput name="sid" type="text">
        <mat-error>Ange ett korrekt sID (s999999)</mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="full-width">
        <mat-label>E-post</mat-label>
        <input formControlName="email" matInput name="email" type="text">
        <mat-error>Ange en korrekt e-postadress</mat-error>
      </mat-form-field>
    </div>
  }

</form>