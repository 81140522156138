import {Routes} from '@angular/router'
import {AdminComponent} from './admin/admin.component'
import {authGuard, superAdminAuthGuard} from './application/auth.guard'
import {
  HOME_PATH,
  LOGIN_PATH,
  LOGS,
  ROLES,
  SUPER_ADMIN
} from './application/types'
import {UserResolver} from './application/user.resolver'
import {LoginComponent} from './components/login/login.component'
import {LogsComponent} from './logs/logs.component'
import {
  RolesListComponent
} from './roles-administration/roles-list/roles-list.component'
import {UsersListComponent} from './users/users-list/users-list.component'


export default [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: HOME_PATH
  },
  {
    path: HOME_PATH,
    component: UsersListComponent,
    canActivate: [authGuard],
    resolve: {
      users: UserResolver
    }
  },
  {
    path: ROLES,
    component: RolesListComponent,
    canActivate: [authGuard]
  },
  {
    path: LOGS,
    component: LogsComponent,
    canActivate: [authGuard]
  },
  {
    path: SUPER_ADMIN,
    component: AdminComponent,
    canActivate: [superAdminAuthGuard]
  },
  {
    path: LOGIN_PATH,
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: LOGIN_PATH,
    pathMatch: 'full'
  }

] satisfies Routes
