import {Injectable} from '@angular/core'
import {forkJoin, Observable} from 'rxjs'
import {RoleService} from '../services/role.service'
import {UserService} from '../services/user.service'

@Injectable({providedIn: 'root'})
export class UserResolver {
  constructor(
    private roleService: RoleService,
    private userService: UserService
  ) {
  }

  resolve(): Observable<any> {
    return forkJoin([this.userService.getUsers(), this.userService.getOffices(), this.roleService.getAllRoles()])
  }
}
