import {HttpClient} from '@angular/common/http'
import {Injectable, signal, WritableSignal} from '@angular/core'
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms'
import {verify} from '@sparbanken-syd/personnummer'
import {map, Observable, switchMap} from 'rxjs'
import {SpbUser} from 'sparbanken-syd-auth-backend'
import {environment} from '../../environments/environment'
import {ServiceBase, Wait} from './service-base.class'

export interface RoleInfo {
  roleName: string
  roleKey: string
  roleDescription: string
  siteKey: string
  siteName: string
}

interface RoleGroup {
  siteKey: string
  siteName: string
  roles: RoleInfo[]
}

export interface SpbUserWithRoles extends SpbUser {
  rolesHeld: RoleGroup[]
  rolesNotHeld: RoleGroup[]
}

@Injectable({
  providedIn: 'root'
})


export class UserService extends ServiceBase {
  public user$: WritableSignal<SpbUserWithRoles> = signal({} as SpbUserWithRoles)
  public users$: WritableSignal<SpbUserWithRoles[]> = signal([])
  public userExpandedElement$: WritableSignal<SpbUser | null> = signal(null)
  public offices$: WritableSignal<string[]> = signal([])


  constructor(private httpClient: HttpClient) {
    super()
  }

  /**
   * Update or create users.
   */
  @Wait('Sparar', 'Sparar användare...')
  public saveUser(user: SpbUser): Observable<SpbUser[]> {
    const url = `${environment.userAdminApi}/users`
    return this.httpClient.put<SpbUser>(url, user)
      .pipe(
        switchMap(() => this.getUsers())
      )
  }

  /**
   * Delete User
   * @param user
   */
  @Wait('Raderar', 'Raderar användare...')
  public deleteUser(user: SpbUser): Observable<any> {
    const url = `${environment.userAdminApi}/users/${user.itemId}`
    return this.httpClient.delete<SpbUser>(url, {body: user})
  }

  public getUsers(): Observable<SpbUserWithRoles[]> {

    // TODO: REMOVE THIS
    // console.log(users)
    // this.users$.set(users)
    //
    // return users

    const url = `${environment.userAdminApi}/users`
    return this.httpClient.get<SpbUserWithRoles[]>(url)
      .pipe(
        map((users: SpbUserWithRoles[]) => {
          this.users$.set(users
            .sort((a, b) => a.name
              .localeCompare(b.name)))
          return users
        })
      )
  }

  /**
   * The application configuration from the backend.
   */
  public getOffices(): Observable<string[]> {
    const url = `${environment.userAdminApi}/data/offices`
    return this.httpClient.get<string[]>(url).pipe(
      map((offices: string[]) => {
        this.offices$.set(offices.sort((a, b) => a.localeCompare(b)))
        return offices
      })
    )
  }

  /**
   * Validator to check if a user already exists.
   *
   */
  /**
   * Validator function to check if a user already exists.
   *
   */
  public userExistValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null
      }

      try {
        return this.userExist(verify(control.value)[12]) ? {exists: control.value} : null
      } catch {
        return null
      }
    }
  }

  private userExist(sub: string): SpbUser | undefined {
    return this.users$().find((user: SpbUser) => user.sub === sub)
  }

}
